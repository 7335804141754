import React from "react"

import Layout from "../../../components/Layout/secondaryPage"
import Success from "../../../components/Success"

const title = "Спасибо"
const subTitle = "Ваша заявка принята"
const description = "В ближайшее время с Вами свяжутся специалисты нашего Банка."

export default function Page() {
  return (
    <Layout>
      <Success title={title} subTitle={subTitle} description={description} />
    </Layout>
  )
}

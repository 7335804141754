import React, { ReactNode } from "react"

import clsx from "clsx"

import Box from "@material-ui/core/Box"
import Container from "@ecom/ui/components/Container"

import * as styles from "./success.module.scss"
import { useFrame } from "../../utils/useFrame"

const defTitle = "Поздравляем!"
const defSubTitle = "Ваша заявка принята!"
const defDesc = (
  <>
    <br className="d-sm-none" /> Чтобы мы смогли доставить Халву, закончите оформление заявки
  </>
)

type Props = {
  title?: ReactNode
  subTitle?: ReactNode
  description?: ReactNode
}

export default function Success({
  title = defTitle,
  subTitle = defSubTitle,
  description = defDesc,
}: Props) {
  const frameRef = useFrame()

  return (
    <div ref={frameRef}>
      <Box
        flex="1 0 auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={clsx(styles.container, "text-center")}
      >
        <Container>
          <h2>{title}</h2>
          <h3 className={styles.desc}>{subTitle}</h3>
          <p className={styles.hint}>{description}</p>
        </Container>
      </Box>
    </div>
  )
}

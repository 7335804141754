import { useEffect, useRef } from "react"

export const useFrame = () => {
  const frameRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Отправить postMessage при инициализаций
    const initialWidth = frameRef.current?.offsetWidth
    const initialHeigth = frameRef.current?.offsetHeight
    const initialMessage = JSON.stringify({
      action: "RESIZE",
      width: `${initialWidth}px`,
      height: `${initialHeigth}px`,
    })
    window.parent.postMessage(initialMessage, "*")

    // Функция для отправки postMessage при изменений размера
    const handleResize = () => {
      const width = frameRef.current?.offsetWidth
      const height = frameRef.current?.offsetHeight
      const resizeMessage = JSON.stringify({
        action: "RESIZE",
        width: `${width}px`,
        height: `${height}px`,
      })
      window.parent.postMessage(resizeMessage, "*")
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return frameRef
}
